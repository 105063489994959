<template>
  <div id="Home">
    <pub-swiper></pub-swiper>

    <div style="width: 100%">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item><img src="../../assets/images/985/home/swiper.png" alt=""></van-swipe-item>
        <!-- <van-swipe-item><img src="../../assets/images/banner/3-2.png" alt=""></van-swipe-item>
        <van-swipe-item><img src="../../assets/images/banner/3-3.png" alt=""></van-swipe-item> -->
      </van-swipe>
    </div>
    <div>
      <div class="topactive">
        <img src="../../assets/images/985/home/shop.png" alt="">
        <img src="../../assets/images/985/home/full.png" alt="">
        <img src="../../assets/images/985/home/kefu.png" alt="">
        <img src="../../assets/images/985/home/qq.png" alt="">
        <img src="../../assets/images/985/home/red.png" alt="">
        <!-- <div @click="RouteJump('/Mall')">商城</div>
        <div @click="RouteJump('/Activity')">福利箱</div>
        <div @click="kefus()">客服</div>
        <div @click="QQ=true">官方群</div>
        <div @click='methodA'>红包</div> -->
      </div>
    </div>
    <div class="navnav">
      <div @click="RouteJump('/box')"><img src="../../assets/images/985/home/open.png" alt=""></div>
      <div @click="RouteJump('/Battle')"><img src="../../assets/images/985/home/pk.png" alt=""></div>
      <div @click="RouteJump('/Replacement')"><img src="../../assets/images/985/home/permutation.png" alt=""></div>
      <div @click="RouteJump('/roll')"><img src="../../assets/images/985/home/roll.png" alt=""></div>
      <div @click="RouteJump('/lucky')"><img src="../../assets/images/985/home/lucky.png" alt=""></div>
      <div @click="RouteJump('/jqiu')"><img src="../../assets/images/985/home/jin.png" alt=""></div>

    </div>
    <div>
      <van-tabbar v-model="active" route style="background:black;margin-bottom:25px" class="bottombac">
        <van-tabbar-item icon="" style="" replace to="/home">
          <span style="color:white">首页</span>
          <template #icon="props">
            <img src="../../assets/images/q2/home/home.png" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="" replace to="/Extension">
          <span style="color:white">推广</span>
          <template #icon="props">
            <img src="../../assets/images/q2/home/tui.png" />
          </template>
        </van-tabbar-item >
        <van-tabbar-item icon="" replace to="/recharge">
          <span style="color:white">充值</span>
          <template #icon="props">
            <img src="../../assets/images/q2/home/recharge.png" />
          </template>
        </van-tabbar-item >
        <van-tabbar-item icon="" replace to="/Warehouse">
          <span style="color:white">背包</span>
          <template #icon="props">
            <img src="../../assets/images/q2/home/back.png" />
          </template>
        </van-tabbar-item >
        <van-tabbar-item icon="" replace to="/Me">
          <span style="color:white">我的</span>
          <template #icon="props">
            <img src="../../assets/images/q2/home/my.png" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    
    </div>
    <div class="bottom">
        <img src="../../assets/images/985/home/bottom.png" alt="">
    </div>
    <div class="redpack">

    </div>
    <div class="QQ" v-show="QQ==true">
      <img src="../../assets/images/q2/sidle/close.png" alt="" class="QQclose" @click="QQ=false">
      <div class="QQimg">
        <div class="dimensional">
          <img src="../../assets/images/q2/recharge/qqs.png" alt="">
        </div>
      </div>
    </div>
    <BroadSide ref="bPage"></BroadSide>
  </div>
</template>

<script>
import { Rooms, GetHotBox } from "@/network/api.js";
import PubSwiper from "@/components/HeaderSwiper/index.vue";
import BroadSide from '@/components/BroadSide/index.vue';
export default {
  name: "Home",
  data() {
    return {
      Headlines: [],
      HotList: [],
      BladeList: [],
      recruitList: [],
      vitalityList: [],
      classicList: [],
      soulList: [],
      XyList: [],
      jinqiu:[],
      active: 0,
      QQ:false,
    };
  },
  components: {
    PubSwiper,
    BroadSide
  },
  filters: {
    StrIntercept(val) {
      return val.length > 9 ? val.substr(0, 9) + "..." : val;
    },
  },
  created() {
    this.GetRooms();
    this.ObtainHotBox();
  },
  methods: {
    kefus(){
      window.open("https://tb.53kf.com/code/client/4ee32a69e6b55ca472ef34b644ccd7418/1")
    },
    methodA(){
      this.$refs.bPage.Changeredshow();
    },
    //Nav路由跳转
    RouteJump(str) {
      if (str == this.$route.fullPath) {
        this.$router.go(0);
      } else {
        this.$router.push(str);
        // this.NavShow = false;
        // this.PerNavShow = false;
      }
    },
    //房间
    GetRooms() {
      Rooms("1", "1").then((res) => {
        // console.log(res.data.data.data)
        this.Headlines = res.data.data.data.slice(0, 2);
        // console.log(this.Headlines)
      });
    },
    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
        this.jinqiu=res.data.data[0]
        this.HotList=this.HotList.filter((item) => {
            return item!=this.jinqiu
        })
        // this.HotList = res.data.data.hotboxlist.slice(0, 5)
        // this.BladeList = res.data.data.hotboxlist.slice(5, 20)
        // this.recruitList = res.data.data.hotboxlist.slice(20, 30)
        // this.vitalityList = res.data.data.hotboxlist.slice(30, 40)
        //this.classicList = res.data.data.hotboxlist.slice(35,40 )
        //this.soulList = res.data.data.hotboxlist.slice(40,45)
        //this.XyList = res.data.data.hotboxlist.slice(45,50)
      });
    },

    //路由跳转房间详情
    toRoom(id) {
      // console.log(id)
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },

    //路由跳转开箱
    ToOpen(id) {
      this.$router.push({
        path: "/Open",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style  lang="scss">
.QQ{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 999;
  .QQclose{
    position: absolute;
    right: 40px;
    top: 120px;
    width: 30px;
    height: 30px;
  }
  .QQimg{
    width: 90%;
    height: 400px;
    background: url('../../assets/images/q2/sidle/QQbac.png') no-repeat center;
    background-size: 100% 100%;
    margin: 150px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .dimensional{
      width: 150px;
      height: 150px;
      border: 5px solid #fff;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.bottombac{
  // background: url('../../assets/images/985/home/bottomnav.png') no-repeat center !important;
  background-size: 100% 100% !important;
}
.bottom{
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  img{
    width: 100%;
  }
}
.navnav{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
  div:nth-child(1){
    width: 100%;
    margin-top: 0px;
  }
  div:nth-child(2){
    width: 100%;
    margin-top: 0px;
  }
  div{
    width: 50%;
    text-align: center;
    margin-top: 20px;
    img{
      width: 100%;
    }
  }
}
.topactive{
  width: 100%;
  display: flex;
  margin: 15px auto;
  img{
    width: 20%;
  }
  div{
    width: 25%;
    text-align: center;
    line-height: 60px;
    color: white !important;
  }
  div:nth-child(1){
    background: url('../../assets/images/q2/home/shop.png') no-repeat center;
    height: 45px;
    background-size: 100% 100%;
  }
  div:nth-child(2){
    background: url('../../assets/images/q2/home/fuli.png') no-repeat center;
    height: 45px;
    background-size: 100% 100%;
  }
  div:nth-child(3){
    background: url('../../assets/images/q2/home/kefu.png') no-repeat center;
    height: 45px;
    background-size: 100% 100%;
  }
  div:nth-child(4){
    background: url('../../assets/images/q2/home/QQ.png') no-repeat center;
    height: 45px;
    background-size: 100% 100%;
  }
  div:nth-child(5){
    background: url('../../assets/images/q2/home/red.png') no-repeat center;
    height: 45px;
    background-size: 100% 100%;
  }
}
.van-swipe-item{
  background: none !important;
}
#Home {
  width: 100%;
  /*padding:10px;*/
  box-sizing: border-box;
  .my-swipe{
    background: url('../../assets/images/985/home/border.png') no-repeat center;
    background-size: 100% 100%;
    padding-top:3px;
    padding-bottom: 3px;
   
    img{
      width: 95%;
      border-radius: 10%;
    }
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 1px;
    text-align: center;
    // background-color: #39a9ed;
    
  }
  .rooms {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .rooms_item {
      width: 49%;
      padding: 0 4px 6px;
      box-sizing: border-box;
      border-radius: 8px;
      background: url(../../assets/images/rooms/roll_item.png) no-repeat center;
      background-size: 100% 100%;
      &:last-child {
        background: url(../../assets/images/rooms/roll_item2.png) no-repeat
          center;
        background-size: 100% 100%;
      }
      .top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pic {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .room_data {
          width: calc(100% - 40px);
          .room_title {
            width: 100%;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
          }
          .room_price {
            width: 100%;
            height: 20px;
            line-height: 20px;
            color: #f4f4f4;
            display: flex;
            span {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: bold;
              color: #f1bc60;
              img {
                width: 18px;
              }
            }
          }
        }
      }

      .center {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        .pic {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: 90% 90% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          img {
            max-width: 110%;
            max-height: 110%;
          }
        }
      }
      .countDown {
        width: 100%;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        .colon {
          display: inline-block;
          width: 20px;
          height: 20px;
          text-align: center;
          color: #fff;
          font-size: 12px;
        }

        .block {
          display: inline-block;
          width: 30px;
          height: 20px;
          background: rgba(48, 57, 81, 0.8);
          border-top: 1px solid #49587e;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4em;
          padding-left: 5px;
          text-align: center;
          background-size: cover;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }
  }
  .Title::before {
    background: none;
  }
  .Title {
    font-family: ninefont;
    font-size: 0.8rem;
    margin-top: 20px;
    background: url('../../assets/images/985/box/title.png') no-repeat center;
    background-size: 130% 20%;
  }
  .Title::after {
    background: none;
  }

  
}
</style>
